<template>
  <div
    :id="`wrapper_${index}`"
    class="max-h-screen overflow-hidden"
    :class="useTheme(slice.primary.theme ?? 'default')"
  >
    <div :class="slice.variation === 'default' ? 'mx-auto' : ''">
      <!-- Content -->
      <div class="md:h-[60vh] md:min-h-[60vh] xl:h-[80vh]">
        <div class="copy relative left-0 top-0 h-full p-10 md:flex md:items-center">
          <h2 class="title1 text-hero-header text-white md:absolute md:left-10 md:top-20 md:text-desktop-hero-header">
            {{ slice.primary.title_first }}
          </h2>
          <h2 class="title2 text-hero-header text-white md:absolute md:bottom-20 md:left-10 md:text-desktop-hero-header">
            {{ slice.primary.title_second }}
          </h2>

          <div class="text md:ml-[30%]">
            <PrismicRichText
              :field="slice.primary.content"
              class="my-10 text-body-copy xl:text-desktop-large-copy"
            />

            <UIButton
              v-for="(button, index) in slice.primary.actions"
              :key="index"
              :type="button.type"
              :label="button.label"
              :link="prismic.asLink(button.link)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Content } from "@prismicio/client";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const prismic = usePrismic();
// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
  getSliceComponentProps<Content.FeaturedTextSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ]),
);

onMounted(() => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: `#wrapper_${props.index.toString()}`,
      start: "top 60%",
      end: "bottom bottom",
      scrub: true,
    },
  });

  tl.from(`#wrapper_${props.index.toString()} .copy`, { y: "100px", opacity: 0, duration: 1 });
  tl.fromTo(`#wrapper_${props.index.toString()} .title2`, { top: "220px" }, { top: "auto", duration: 2.5, delay: 1 });
  tl.from(`#wrapper_${props.index.toString()} .text`, { opacity: 0, duration: 1 }, "-=.5");
});
</script>
